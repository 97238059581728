<template>
  <div class="box">
    <div class="box_title">
      <h1>退货运单</h1>
    </div>
    <div class="box_forms">
      <a-form-model :model="form" layout='inline'>
        <a-form-model-item label="运单号">
          <a-input v-model="form.waybillNo" />
        </a-form-model-item>
        <a-form-model-item label="申请日期">
          <a-range-picker v-model="date" :show-time="{
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      }" format="YYYY-MM-DD HH:mm:ss" @change='change' />
        </a-form-model-item>

        <a-form-model-item>
          <div class="btns">
            <a-button type='primary' @click="search">查询</a-button>
            <a-button style="margin-left:15px" @click="reset">重置</a-button>
          </div>
        </a-form-model-item>

      </a-form-model>

    </div>
    <div class="box_table">
      <a-table size="small" :columns="columns" :scroll="{ x: 'max-content' }" :rowKey="(record) => record.id" :data-source="data" :customRow="customRow" :rowClassName="rowClassName" bordered>
        <template slot="created_at" slot-scope="text">
          <span>{{moment(text *1000).format("YYYY-MM-DD HH:mm:ss")}}</span>
        </template>
        <a slot="name" slot-scope="text,record">
          <a-button type="link" @click="checkInfo(record)">查看详情</a-button>
          <a-button type="link" @click="confirmGoods(record)" v-if="record.is_sign==0">确认收货</a-button>
        </a>
      </a-table>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import {
    backGoodsList,
    goodSign
  } from "@/api/ReturnWaybill"
  export default {
    name: 'ReturnBill',
    data() {
      return {
        selectIndex: null,
        date: [],
        form: {
          applyDateStart: '',
          applyDateEnd: '',
          waybillNo: ''
        },
        moment,
        data: [],
        columns: [{
            title: '运单号',
            dataIndex: 'waybill_no',
            key: 'waybill_no',

          },
          {
            title: '运单类型',
            dataIndex: 'waybill_type',
            key: 'waybill_type',
            customRender: (text) => {
              let str = ""
              if (text === 1) {
                str = "FBA运单"
              } else if (text === 2) {
                str = "非FBA运单"
              }
              return str;
            }
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            customRender: (text, record) => {
              let str = ''
              if (record.status === 1) {
                str = "退货中"
              } else if (record.status === 2) {
                if (record.is_sign == 0) {
                  str = "已送达"
                } else {
                  str = '签收'
                }
              }
              return str
            }

          },
          {
            title: '申请时间',
            dataIndex: 'created_at',
            key: 'created_at',
            scopedSlots: {
              customRender: 'created_at'
            },

          },
          {
            title: '退货原因',
            dataIndex: 'reason',
            key: 'reason',
          },
          {
            title: '操作',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: {
              customRender: 'name'
            },
          },

        ]
      }
    },
    created() {
      this.getData()
    },
    methods: {
      // 点击的样式
      rowClassName(record, index) {
            return (index === this.selectIndex) ? 'row-active' : ''
        },
        // 点击的效果
        customRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.selectIndex = index;
                        console.log(this.selectIndex)
                    }
                }
            }
        },
      checkInfo(row) {
        this.$router.push({
          path: "/Waybill/_details",
          query: {
            id: row.waybill_id
          }
        });
      },
      getData() {
        backGoodsList().then(res => {
          this.data = res.data.list
        })
      },
      change(val) {
        this.form.applyDateStart = moment(val[0]).format('YYYY:MM:DD HH:mm:ss')
        this.form.applyDateEnd = moment(val[1]).format('YYYY:MM:DD HH:mm:ss')
      },
      reset() {
        this.date = []
        Object.keys(this.form).forEach(ele => {
          this.form[ele] = ''
        })
      },
      search() {
        backGoodsList(this.form).then(res => {
          this.data = res.data.list
        })
      },
      confirmGoods(row) {
        if (row.status == 1) {
          this.$message.error("司机未送达")
        } else if (row.status == 2) {
          goodSign({
            goodsId: row.id
          }).then(() => {
            this.$message.success("已成功签收货物")
            this.getData()
          })
        }
      }
    },

  }
</script>

<style lang="less" scoped>
  .box {
    position: relative;
    .box_title {
      padding-top: 10px;
      padding-left: 15px;
      border-bottom: 1px solid #e9e8eb;
    }

    .box_forms {
      padding: 15px;
      border-bottom: 1px solid #e9e8eb;

    }

    .box_table {
      padding: 15px;

    }
  }
</style>